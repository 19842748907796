<template>
    <v-main>
      <v-container >
        <PriceUpdate :formupdate='formupdate' v-if="formupdate" :uitemm='uitem' :config='config' @formupdate='geUpform' @formRefresh='refresh'/>
              
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                {{ $t('id') }}
              </th>
              <th class="text-center">
                {{ $t('name')}}
              </th>
              <th class="text-center">
                {{ $t('price') }}
              </th>
              <th class="text-center">
                {{ $t('quantity') }}
              </th>
              <th class="text-center">
                {{ $t('update') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,i) in items"
              :key="i"
              class="text-center"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.quantity }}</td>
              <td>
                <v-btn
                class="ma-2"
                outlined
                color="indigo"
                @click="update(item)"
                >
                  <v-icon>{{icons.mdiPencil}}</v-icon>
                </v-btn>
              </td>

            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-container>
    </v-main>
</template>
<script>
import {
    mdiPencil,
    mdiEye,
    mdiRotateRight,
    mdiContentSave 
  } from '@mdi/js'
import axios from 'axios'
import moment from "moment"
import store from '../../store/user'
import PriceUpdate from '../parts/PriceUpdate.vue'
import i18n from '@/plugins/i18n'
  export default {
    data: () => ({
        icons: {
            mdiPencil,
            mdiEye,
            mdiRotateRight,
            mdiContentSave 
        },
        rules: {
            name: [
                val => !!val || "Boş qoymayın!",
                val => (val && val.length <= 25) || "Adı 25 simvoldan kiçik olmalıdır!",
            ],
            req: [
                val => !!val || "Boş qoymayın!",
            ]
        },
        loading: true,
        searchloading: false,
        exportloading: false,
        hover:false,
        config: {
            headers: {
                Authorization: ""
            }
        },

        formupdate: false,
        items: [],
    }),
    methods: {
        update(item) {
            this.uitem = { ...item };
            this.formupdate = true;
        },
        geUpform(value){
          this.formupdate = value;
        },
        refresh() {
            axios.get(this.$store.getters.getUrl + "Present/All", {
                headers: this.config.headers
            }).then(response => {
                this.items = response.data.value;
                this.searchloading = false;
            }).catch(error => {
                this.$toast.error("Xəta var!");
            });
        },
        format_date(k) {
            return k ? moment(k).format("DD.MM.Y HH:mm:ss") : "";
        },
    },
    created() {
        this.config.headers.Authorization = "Bearer " + store.getters.getToken;
        this.refresh();
        
    },
    components: { PriceUpdate }
}
</script>
