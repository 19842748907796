<template>
    <v-dialog
        v-model="formupdate"
        persistent
        max-width="900px"
    >
        <v-card>
        <v-form @submit.prevent="crt" ref="formupdate">
        <v-card-title>
            <span class="headline">Hədiyyə redaktə</span>
        </v-card-title>
        <v-card-text>
            <v-container>
            <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                <v-text-field
                    v-model="uitem.name"
                    :label="$t('name')"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                <v-text-field
                    v-model="uitem.quantity"
                    :label="$t('quantity')"
                  ></v-text-field>
                </v-col>

            </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
            class="text-none"
            >
            Bağla
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            type="submit"
            class="text-none"
            >
            Yaddaşa yaz
            </v-btn>
        </v-card-actions>
        </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'
import i18n from '@/plugins/i18n';
export default {
    data : () =>({
        rules: {
            name: [
                val => !!val || "Boş qoymayın!",
                val => (val && val.length <= 25) || "Adı 25 simvoldan kiçik olmalıdır!",
            ],
            req: [
                val => !!val || "Boş qoymayın!",
            ],
        },
        uitem: {},

        conf: {},
    }),
    props: ['formupdate','config', 'uitemm',],
    methods: {
        close(){
            this.$emit('formupdate', false);
        },
        crt() {
            if (this.$refs.formupdate.validate()) {
                this.uitem.quantity = parseInt(this.uitem.quantity)
                axios.put(this.$store.getters.getUrl + "Present/Update", 
                {
                "presentId": this.uitem.id,
                "quantity": this.uitem.quantity,
                "price": this.uitem.price
                }, 
                this.config).then(response => {
                    if (response.data.success) {
                        this.$toast.success("Uğurla dəyişdirildi!");
                        this.$emit('formupdate', false);
                        this.$emit('formRefresh', true);
                    }
                    else {
                        this.$toast.error("Bir xəta baş verdi. Bir daha sınayın!");
                    }
                }).catch(() => {
                    this.$toast.error("Bir xəta baş verdi!");
                });
            }
            else {
                this.$toast.error("Xanalar düzgün doldurulmayib. Bir daha xanaları yoxlayın!");
            }
        },
    },
    created(){
        this.conf = this.config;
        this.uitem = this.uitemm;
    },
    computed:{
    }

}
</script>
