<template>
    <v-main>
      <v-container >
        <v-row>
                <v-spacer/>
                <v-col
                cols="12"
                sm="12"
                md="12"
                class="d-inline-flex"
                >
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reguest.startdate"
                      :label="$t('start_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="reguest.startdate"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reguest.enddate"
                      :label="$t('end_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="reguest.enddate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  class="mx-3"
                  :label="$t('name')"
                  v-model="reguest.Name"
                ></v-text-field>
                <v-text-field
                  class="mx-3"
                  :label="$t('surname')"
                  v-model="reguest.Surname"
                ></v-text-field>
                <v-text-field
                  class="ml-3"
                  :label="$t('number')"
                  v-model="reguest.msisdn"
                ></v-text-field>
                <v-btn
                class="ma-2 text-none"
                :disabled="searchloading"
                :loading="searchloading"
                outlined
                color="indigo"
                @click="refresh"
                >
                {{ $t('search')}} 
                </v-btn>
                <v-btn
                  class="ma-2 text-none"
                  outlined
                  :disabled="exportloading"
                  :loading="exportloading"
                  color="success"
                  @click="exportt"
                >
                  Export 
                </v-btn>
                </v-col>
        </v-row>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                {{ $t('date') }}
              </th>
              <th class="text-center">
                {{ $t('name') }}
              </th>
              <th class="text-center">
                {{ $t('surname') }}
              </th>
              <th class="text-center">
                {{ $t('number') }}
              </th>
              <th class="text-center">
                {{ $t('email') }}
              </th>
              <th>Toplam təsdiq olunmuş qeydiyyat kodlarının sayı</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              class="text-center"
            >
              <td>{{ format_date(item.registerDate) }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.surname }}</td>
              <td>{{ item.msisdn }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.approvedCoupons }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-container>
    </v-main>
</template>
<script>
import {
    mdiPencil,
  } from '@mdi/js'
import axios from 'axios'
import moment from 'moment'
import store from '../../store/user'
  export default {
    data: () => ({
      icons: {
        mdiPencil,
      },
      rules:{
        name:[
          val => !!val || "Boş qoymayın!"
        ]
      },
      loading: true,
      searchloading: false,
      exportloading: false,
      config : {
          headers : {
              Authorization: ''
          }
      },
      reguest: {
        msisdn : '',
        Name:"",
        Surname: "",
        startdate : moment().add(-1, 'days').format('Y-MM-DD'),
        enddate : moment().add(1, 'days').format('Y-MM-DD')
      },
      items : [],
      menu: false,
      menu2: false

    }),
    methods:{
      onPageChange(){
        this.refresh()
      },
      exportt(){
        this.exportloading = true
        axios.get(this.$store.getters.getUrl+'excel/exportusers',{...this.config,responseType: 'blob', params:this.reguest})
        .then(response => {
            const url = URL.createObjectURL(new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', "users")
            document.body.appendChild(link)
            link.click()
            this.exportloading = false

        })
        .catch(() => {
            this.$toast.error('Xəta var!')
        })
      },

      refresh(){
        axios.get(this.$store.getters.getUrl+'user/all',{
            headers: this.config.headers,
            params: this.reguest
        }).then(response=>{
            this.items= response.data.value
            this.searchloading = false
          }).catch(() => {
            
            this.$toast.error('Xəta var!')
          })
      },
      format_date(k){
            return k ? moment(k).format('DD.MM.Y HH:mm:ss') : '';
        },
      format_api_date(k){
          return k ? moment(k).format('Y-MM-DD') : '';
      },
    },

    created(){
      this.config.headers.Authorization = 'Bearer ' + store.getters.getToken
      this.refresh()
    },
    computed : {   

    }

  }
</script>