<template>
    <v-main>
      <v-container
        class="no-padding all-page"
        fluid
      >
        <v-row
        class="login-row"
        >
          <v-col
            cols="12"
            sm="0"
            md="6"
            class="d-none justify-center d-flex  align-self-center no-padding back-img"
          >
            <img src="@/assets/img/carts web.png" alt="Kinder" class="img" width="70%">
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            class="no-padding justify-center d-flex  align-self-center"
          >
            <v-card 
            elevation="0"
            class="px-5 mx-4 login-cart justify-center"
            >
              <v-card-text>
                <v-form @submit.prevent="signIn" id="sign" ref="form">
                  <v-text-field
                    class="mb-3"
                    label="İstifadəçi adı"
                    v-model="username"
                    :rules='rules.username'
                    type="text"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    label="Parol"
                    v-model="password"
                    :rules="rules.password"
                    type="password"
                    outlined
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                <v-btn  
                class="px-10 text-none"
                color="primary"
                form="sign"
                :loading="loading"
                dark
                x-large
                rounded
                type="submit"
                >Giriş</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
</template>
<script>
  import axios from "axios"
  import store from '../store/'
  import user from '../store/user'
  export default {
    props: {
      
    },
    data: ()=>({
      username : '',
      password : '',
      loading:false,
      rules : {
        username: [
          val => val != '' || 'Doldurun!'
        ],
        password: [
          val => val != '' || "Doldurun!"
        ]
      }


    }),
    methods: {
      signIn(){
        if(this.$refs.form.validate()){
          
          this.loading = true
          axios.post(store.getters.getUrl+'user/login',{msisdn:this.username,password:this.password})
          .then(response => {
            if(response.data.success){
              this.$toast.success('Success')
            
              user.dispatch('updateLogin',{expires:1, user:response.data.value})

              this.loading = false
              location.href='/'
              // this.$router.push('/')
            }
            else {
              this.$toast.error('İstifadəçi adı və ya parol səhvdir!')
              this.loading = false
            }
          })
        }
        else{
          this.$toast.error('Xanalar boşdur!')
          this.loading = false
        }
      },

    },
    computed : {   
      formIsValid(){
        if(this.username=='')
        return false
        if(this.password=='')
        return false

        return true

      }
    },
  }
</script>
<style>
.back-img{
    /* background: url('../assets/img/login_img.jpg'); */
}
.login-cart{
  width: 650px;
}
.login-row{
  width: 100%!important;
  height: 100%;
}
.all-page{
  width: 100%;
  height: 100%;
  background-color: #89D5F6;
}
.img{
  height: 500px;
}
</style>