<template>
    <v-dialog
        v-model="formupdate"
        persistent
        max-width="900px"
    >
        <v-card>
        <v-form @submit.prevent="crt" ref="formupdate">
        <v-card-title>
            <span class="headline">Çek yoxlanışı</span>
        </v-card-title>
        <v-card-text>
            <v-container>
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                <v-textarea
                    outlined
                    label="İmtina edəcəyiniz təqdirdə not yazın"
                    v-model="uitem.Note"
                    rows="5"
                ></v-textarea>
                </v-col>
                <!-- <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                <v-text-field
                    label="Kupon sayı"
                    v-model="uitem.CouponCount"
                    type="number"
                ></v-text-field>
                </v-col> -->
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                  <v-checkbox
                    v-model="uitem.IsApproved"
                    label="Təstiq et"
                  ></v-checkbox>
                  </v-col>
            </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
            class="text-none"
            >
            Bağla
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            type="submit"
            class="text-none"
            >
            Yaddaşa yaz
            </v-btn>
        </v-card-actions>
        </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
    data : () =>({
        rules: {
            name: [
                val => !!val || "Boş qoymayın!",
                val => (val && val.length <= 25) || "Adı 25 simvoldan kiçik olmalıdır!",
            ],
            req: [
                val => !!val || "Boş qoymayın!",
            ],
        },
        uitem: {
            ReceiptId: '',
            IsApproved: false,
            Note: '',
            CouponCount: 1
        },
        conf: {},
    }),
    props: ['formupdate','config', 'uitemm',],
    methods: {
        close(){
            this.$emit('formupdate', false);
        },
        crt() {
            this.uitem.CouponCount = Number(this.uitem.CouponCount)
            if (this.$refs.formupdate.validate()) {
                axios.post(this.$store.getters.getUrl + "receipt/approve", this.uitem, this.config).then(response => {
                    if (response.data.success) {
                        this.$toast.success("Uğurla dəyişdirildi!");
                        this.$emit('formupdate', false);
                        this.$emit('formRefresh', true);
                    }
                    else {
                        this.$toast.error("Bir xəta baş verdi. Bir daha sınayın!");
                    }
                }).catch(() => {
                    this.$toast.error("Bir xəta baş verdi!");
                });
            }
            else {
                this.$toast.error("Xanalar düzgün doldurulmayib. Bir daha xanaları yoxlayın!");
            }
        },
    },
    created(){
        this.conf = this.config;
        this.uitem.ReceiptId = this.uitemm.id;


    },
    computed:{
    }

}
</script>
