<template>
    <v-main>
      <v-container >
        <WinnerUpdate :formupdate='formupdate' v-if="formupdate" :config='config' :statuss="statuss" @formupdate='geUpform' @formRefresh='refresh'/>
        <v-row>
                <v-spacer/>
                <v-col
                cols="12"
                sm="12"
                lg="12"
                md="12"
                class="d-md-inline-flex"
                >
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reguest.startdate"
                      label="Başlama tarixi"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="reguest.startdate"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reguest.enddate"
                      label="Bitmə tarixi"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="reguest.enddate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  class="mx-3"
                  label="Mobil nömrə"
                  v-model="reguest.msisdn"
                ></v-text-field>
                <v-text-field
                  class="mx-3"
                  label="Kupon nömrəsi"
                  v-model="reguest.code"
                ></v-text-field>
                <v-select
                    :items="statuss"
                    v-model="reguest.prizetype"
                    :item-text="'name'"
                    :item-value="'id'"
                    label="Hədiyyə"
                  ></v-select>
                <v-btn
                class="ma-2 text-none"
                :disabled="searchloading"
                :loading="searchloading"
                outlined
                color="indigo"
                @click="refresh"
                >
                Axtar 
                </v-btn>
                <v-btn
                class="ma-2"
                outlined
                color="success"
                @click="update()"
                >
                  <v-icon>{{icons.mdiPencil}}</v-icon>
                </v-btn>
                <v-btn
                class="ma-2 text-none"
                :disabled="exportloading"
                :loading="exportloading"
                outlined
                color="success"
                @click="exportt"
                >
                Export
                </v-btn>
                </v-col>
        </v-row>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                Tarix
              </th>
              <th class="text-center">
                Ad
              </th>
              <th class="text-center">
                Soyad
              </th>
              <th class="text-center">
                Mobil nömrə
              </th>
              <th class="text-center">
                Kupon nömrəsi
              </th>
              <th class="text-center">
                Moderasiya vaxtı
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              class="text-center"
            >
              <td>{{ format_date(item.checkAddDate) }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.surname }}</td>
              <td>{{ item.msisdn }}</td>
              <td>{{ item.couponCode }}</td>
              <td>{{ format_date(item.winDate) }}</td>
              <td>
                <v-btn
                class="ma-2"
                outlined
                color="error"
                @click="deletee(item)"
                >
                  <v-icon>{{icons.mdiDelete}}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-container>
    </v-main>
</template>
<script>
import {
    mdiPencil,
    mdiDelete
  } from '@mdi/js'
import axios from 'axios'
import moment from "moment"
import Swal from 'sweetalert2'
import store from '../../store/user'
import WinnerUpdate from '../parts/WinnerUpdate.vue'
  export default {
    data: () => ({
        icons: {
            mdiPencil,
            mdiDelete
        },
        rules: {
            name: [
                val => !!val || "Boş qoymayın!",
                val => (val && val.length <= 25) || "Adı 25 simvoldan kiçik olmalıdır!",
            ],
            req: [
                val => !!val || "Boş qoymayın!",
            ]
        },
        loading: true,
        searchloading: false,
        exportloading: false,
        formupdate: false,
        config: {
            headers: {
                Authorization: ""
            }
        },
        statuss: [],
        menu: false,
        menu2: false,
        reguest: {
            msisdn: "",
            code: "",
            prizetype: 1,
            startdate: "",
            enddate: ""
        },
        items: [],
    }),
    methods: {
        onPageChange() {
            this.refresh();
        },
        update() {
            this.formupdate = true;
        },
        deletee(item){
          Swal.fire({
            title: 'Sil',
            text: "Əminsiniz?",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: "İmtina",
            confirmButtonText: 'Təsdiq'
            }).then((result) => {
            if (result.isConfirmed) {
              axios.post(this.$store.getters.getUrl+'winner/remove',{id:item.id},this.config).then(response=>{
                if(response.data.success){
                  this.$toast.success('Uğurla silindi!')
                  Swal.fire(
                  'Uğurla silindi!',
                  '',
                  'success'
                  )
                  this.refresh()
                }
                else{
                  this.$toast.error('Bir xəta baş verdiş Bir daha sınayın!')
                  Swal.fire(
                      'Xəta.',
                      'Bir xəta baş verdiş Bir daha sınayın!',
                      'error'
                  )
                }
              })
            }
            })
        },
        geUpform(value){
          this.formupdate = value;
        },
        exportt() {
            this.exportloading = true;
            axios.get(this.$store.getters.getUrl + "excel/exportwinners", { ...this.config, responseType: "blob", params: this.reguest })
                .then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "winners");
                document.body.appendChild(link);
                link.click();
                this.exportloading = false;
            })
                .catch(() => {
                this.$toast.error("Xəta var!");
            });
        },
        refresh() {
            axios.get(this.$store.getters.getUrl + "winner/all", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                this.items = response.data.value;
                this.searchloading = false;
            }).catch(error => {
                this.$toast.error("Xəta var!");
                this.searchloading = false;
            });
        },
        allPresents() {
            axios.get(this.$store.getters.getUrl + "Presents/AllPresents", {
                headers: this.config.headers
            }).then(response => {
                this.statuss = response.data.value;
                this.searchloading = false;
            }).catch(error => {
                this.$toast.error("Xəta var!");
                this.searchloading = false;
            });
        },
        format_date(k) {
            return k ? moment(k).format("DD.MM.Y HH:mm:ss") : "";
        },
    },
    created() {
        this.config.headers.Authorization = "Bearer " + store.getters.getToken;
        this.refresh();
        this.allPresents();
    },
    computed: {},
    components: { WinnerUpdate }
}
</script>