
<template>
  <v-app id="inspire">
    <v-app-bar
      app
      clipped-right
      color="blue"
      elevation="5"
    >
      <v-app-bar-nav-icon large @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- <v-toolbar-title>PromoCRM</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <!-- <v-btn-toggle
        class="mr-5"
        v-model="lang"
        variant="outlined"
        divided
        @change="changeLocation"
      >
        <v-btn value="az" :disabled='lang=="az"'>
          <span>AZ</span>
        </v-btn>

        <v-btn value="ru" :disabled='lang=="ru"'>
          <span>RU</span>
        </v-btn>
      </v-btn-toggle> -->
      <v-btn class="mx-2" outlined  @click="logOut" :loading="loading">
        <v-icon large>logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list >
        <v-list-item link class="ma-1">

          <v-list-item-avatar>
                <img
                  src="../assets/img/logo.png"
                  alt="John"
                >
          </v-list-item-avatar>
          <v-list-item-title >TessPromo</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link to="/">
          <v-list-item-action>
            <v-icon x-large>list</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('main_page') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/winner">
          <v-list-item-action>
            <v-icon x-large>{{ icons.mdiCup }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Qaliblər</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item link to="/check">
          <v-list-item-action>
            <v-icon x-large>{{ icons.mdiApplication }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Çek yoxlanışı</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->


        <v-list-item link to="/statistic">
          <v-list-item-action>
            <v-icon x-large>{{ icons.mdiChartBar }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('qebz_statistic') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/statistic1">
          <v-list-item-action>
            <v-icon x-large>{{ icons.mdiChartBar }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>1 AZN Statistika</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/statistic2">
          <v-list-item-action>
            <v-icon x-large>{{ icons.mdiChartBox }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('statistic') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item link to="/presents">
          <v-list-item-action>
            <v-icon x-large>{{ icons.mdiCup }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('presents') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-item link to="/user">
          <v-list-item-action>
            <v-icon x-large>{{ icons.mdiAccount }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('users') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>


<router-view/>


    <v-footer
      app
      color="blue"
    >
      <span>Kibrit</span>
      <v-spacer></v-spacer>
      <span>&copy; {{ new Date().getFullYear()}}</span>
    </v-footer>
  </v-app>
</template>

<script>
  import store from '../store/user'
    import {
    mdiAccount,
    mdiAccountBox,
    mdiPencil,
    mdiCup,
    mdiApplication,
    mdiAlertDecagram,
    mdiChartBar,
    mdiChartBox,
    mdiPackage
  } from '@mdi/js'
import user from '../store/user'
  export default {
    props: {
      // source: String,
    },
    data: () => ({
      lang:"az",
      icons: {
        mdiAccount,
        mdiAccountBox,
        mdiPencil,
        mdiCup,
        mdiApplication,
        mdiAlertDecagram,
        mdiChartBar,
        mdiChartBox

      },
      user: {},
      drawer: true,
      loading:false,
      username:''
    }),
    methods:{
      changeLocation(){
        console.log(this.lang)
        if(this.$i18n.locale == 'az' && this.lang=="ru"){
          localStorage.setItem('lang','ru')
          this.$i18n.locale = 'ru'
        }
        else{
          localStorage.setItem('lang','az')
          this.$i18n.locale='az'
        }
        // location.reload()
      },
      logOut(){
        this.loading = true

        store.dispatch('updateLogin',{user:'', expires:-1})

        this.loading = false
        location.href='/login'
        //  this.$router.push('/login')
      }
    },
    created(){
      this.user = user.getters.getUser
      if (localStorage.getItem('lang')){
        this.lang = localStorage.getItem('lang');
      }
      else {
        localStorage.setItem('lang', 'az');
        this.lang = 'az';
      }
    },
  }
</script>