<template>
    <v-dialog
        v-model="formupdate"
        persistent
        max-width="900px"
    >
        <v-card>
        <v-form @submit.prevent="crt" ref="formupdate">
        <v-card-title>
            <span class="headline">Qalib əlavə et</span>
        </v-card-title>
        <v-card-text>
            <v-container>
            <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                <v-row>
                    <v-text-field
                    label="Kupon kodu"
                    v-model="reguest.CouponCode"
                  
                ></v-text-field>
                <v-btn
                class="ma-2 text-none"
                :disabled="searchloading"
                :loading="searchloading"
                outlined
                color="indigo"
                @click="check"
                >
                Yoxla 
                </v-btn>
                </v-row>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <v-text-field
                        label="Adı"
                        v-model="uitem.name"
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <v-text-field
                        label="Soyadı"
                        v-model="uitem.surname"
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <v-text-field
                        label="Nömrəsi"
                        v-model="uitem.msisdn"
                        readonly
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                <v-datetime-picker label="Qalib vaxtı"  v-model="reguest.WinDate" clearText="Təmizlə"/> 
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                <v-select
                    :items="statuss"
                    v-model="reguest.prizetype"
                    :item-text="'name'"
                    :item-value="'id'"
                    label="Hədiyyə tipi"
                  ></v-select>
                </v-col>
            </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="close"
            class="text-none"
            >
            Bağla
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            type="submit"
            class="text-none"
            >
            Yaddaşa yaz
            </v-btn>
        </v-card-actions>
        </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    data : () =>({
        rules: {
            name: [
                val => !!val || "Boş qoymayın!",
                val => (val && val.length <= 8) || "Adı 9 simvoldan kiçik olmalıdır!",
                val => (val && val.length >= 8) || "Adı 7 simvoldan böyük olmalıdır!",
            ],
            req: [
                val => !!val || "Boş qoymayın!",
            ],
        },
        reguest: {
            prizetype:0,
            CouponCode:'',
            WinDate: ''
        },
        uitem: {
            user:{}
        },
        searchloading: false,
        conf: {},
    }),
    props: ['formupdate','config', 'statuss'],
    methods: {
        close(){
            this.$emit('formupdate', false);
        },
        check() {
            if (this.$refs.formupdate.validate()) {
                axios.get(this.$store.getters.getUrl + "user/userbyCode", {
                headers: this.conf.headers,
                params: {code:this.reguest.CouponCode}
            }).then(response => {
                    if (response.data.success) {
                        this.uitem = response.data.value
                    }
                    else {
                        this.$toast.error("Bir xəta baş verdi. Bir daha sınayın!");
                    }
                }).catch(() => {
                    this.$toast.error("Bir xəta baş verdi!");
                });
            }
            else {
                this.$toast.error("Xanalar düzgün doldurulmayib. Bir daha xanaları yoxlayın!");
            }
        },
        crt() {
            if (this.$refs.formupdate.validate()) {
                this.reguest.WinDate = moment(this.reguest.WinDate).format("Y-MM-DDTHH:mm:ss")
                axios.post(this.$store.getters.getUrl + "winner/add", {... this.reguest, 
                    winDate: this.uitem.addDate,
                    Name: this.uitem.name,
                    Surname: this.uitem.surname,
                    Msisdn: this.uitem.msisdn,
                    couponCode: this.reguest.CouponCode,
                    prizeId: Number.parseInt(this.reguest.prizetype)
                }, this.config).then(response => {
                    if (response.data.success) {
                        this.$toast.success("Uğurla əlavə edildi!");
                        this.$emit('formupdate', false);
                        this.$emit('formRefresh', true);
                    }
                    else {
                        this.$toast.error("Bir xəta baş verdi. Bir daha sınayın!");
                    }
                }).catch(() => {
                    this.$toast.error("Bir xəta baş verdi!");
                });
            }
            else {
                this.$toast.error("Xanalar düzgün doldurulmayib. Bir daha xanaları yoxlayın!");
            }
        },
    },
    created(){
        this.conf = this.config;
    },
    computed:{
    }

}
</script>
