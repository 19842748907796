import Vue from "vue"
import VueRouter from "vue-router"
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import Index from './components/dashboard/Index'
import User from './components/dashboard/User'
import Campaign from './components/dashboard/Campaign'
import Statistic from './components/dashboard/Statistic'
import Statistic1 from './components/dashboard/Statistic1'
import StatisticAll from './components/dashboard/StatisticAll'
import Winner from './components/dashboard/Winner'
import VueMeta from 'vue-meta'
import DatetimePicker from 'vuetify-datetime-picker'
import Products from './components/dashboard/Products'
import guest from './middleware/guest'
import auth from './middleware/auth'
import store from './store/user'
import i18n from './plugins/i18n';
import middlewarePipeline from './middlewarePipeline'
Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(DatetimePicker)
export const router = new VueRouter({
    routes : [
        
        {
            path : '/login',
            component : Login,
            name : 'login',
            meta : {
                title : i18n.t('router_title'),
                middleware: [
                    guest,
                ]
            }
        },
        {
            path : '/',
            component : Dashboard,
            children: [
                {
                    path: '/',
                    component: Index,
                    name : 'dashboard',
                    meta : {
                        title : i18n.t('router_title'),
                        middleware: [
                            auth,
                        ]
                    },


                },
                {
                    path: '/user',
                    component: User,
                    name : 'user',
                    meta : {
                        title : i18n.t('users'),
                        middleware: [
                            auth
                        ]
                    },


                },
                {
                    path: '/check',
                    component: Campaign,
                    name : 'check',
                    meta : {
                        title : i18n.t('check_check'),
                        middleware: [
                            auth
                        ]
                    },


                },
                {
                    path: '/winner',
                    component: Winner,
                    name : 'winner',
                    meta : {
                        title : 'Qaliblər',
                        middleware: [
                            auth
                        ]
                    },


                },

                {
                    path: '/statistic',
                    component: Statistic,
                    name : 'statistic',
                    meta : {
                        title : i18n.t('statistic'),
                        middleware: [
                            auth
                        ]
                    },


                },
                {
                    path: '/statistic1',
                    component: Statistic1,
                    name : 'statistic',
                    meta : {
                        title : "1 AZN Statistika",
                        middleware: [
                            auth
                        ]
                    },

                },

                {
                    path: '/statistic2',
                    component: StatisticAll,
                    name : 'statistic2',
                    meta : {
                        title : i18n.t('statistic'),
                        middleware: [
                            auth
                        ]
                    },


                },
                {
                    path: '/presents',
                    component: Products,
                    name : 'presents',
                    meta : {
                        title : i18n.t('presents'),
                        middleware: [
                            auth
                        ]
                    },


                },

                // {path: '/activity',name: 'Activity', component: activity},
            ],
        },
        // {
        //     name : 'edit-param',
        //     path : '/edit',
        //     component : Edit
        // },
        // dinamik root teyin edilir icine deyisen gondere bilmek ucun
        {
            path : '*',
            redirect : '/'
        }
    ],
    mode : "history"
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })

  })