<template>
    <v-main>
      <v-container >

                <v-row>
                <v-spacer/>
                <v-col
                cols="12"
                sm="12"
                lg="12"
                md="12"
                class="d-md-inline-flex"
                >
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reguest.startdate"
                      :label="$t('start_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="reguest.startdate"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="reguest.enddate"
                      :label="$t('end_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="reguest.enddate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
                <!-- <v-text-field
                  class="mx-3"
                  label="Mobil nömrə"
                  v-model="reguest.msisdn"
                ></v-text-field>
                <v-text-field
                  class="mx-3"
                  label="Kupon nömrəsi"
                  v-model="reguest.couponCode"
                ></v-text-field> -->
                <!-- <v-select
                  class="mx-3"
                  :items="statuss"
                  v-model="reguest.status"
                  :item-text="'name'"
                  :item-value="'id'"
                  :label="$t('status')"
                  ></v-select> -->
                <v-btn
                class="ma-2 text-none"
                :disabled="searchloading"
                :loading="searchloading"
                outlined
                color="indigo"
                @click="refresh"
                >
                  {{ $t('search') }}
                </v-btn>
                <v-btn
                class="ma-2 text-none"
                :disabled="exportloading"
                :loading="exportloading"
                outlined
                color="success"
                @click="exportt"
                >
                Export
                </v-btn>
                <!-- <v-btn
                class="ma-2 text-none"
                :disabled="exportloading"
                :loading="exportloading"
                outlined
                color="success"
                @click="exporttx"
                >
                ExportX
                </v-btn> -->
                </v-col>
        </v-row>
        <br><br><br>
        <v-simple-table dense>
        <template v-slot:default>

          <tbody>
            <tr>
              <td><h2>{{ $t('users') }}</h2></td>
              <td><h2>{{ userCount }}</h2></td>
            </tr>
            <br>

            <br>
            <tr>
              <td><h2>{{ $t('SuccessfulRegisteration') }}</h2></td>
              <td><h2>{{ SuccessfulRegisteration }}</h2></td>
            </tr>
            <br>
            <tr>
              <td><h2>{{ $t('FailedRegisteration') }}</h2></td>
              <td><h2>{{ FailedRegisteration }}</h2></td>
            </tr>
            <br>
            <tr>
              <td><h2>İstifadəçi alışlarının orta məbləği</h2></td>
              <td><h2>{{ buyAvaragePrice }}</h2></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
        <br>
        <br>
        <br>
        <h2 class="text-center">{{ $t('city_qebz') }}</h2>
        <br>
        <BarChart v-if="!searchloading" :label="$t('cities')" :backgroundColor="'#e83c1a'" :data="cityCount" :labels="cityName" />

        <br>
        <v-divider></v-divider>
        <br>
        <br>
        <h2 class="text-center">{{  $t('operator_qebz') }}</h2>
        <br>
        <PieChart v-if="!searchloading"   :data="operatorCount" :labels="operatorName" />

        <br>
        <v-divider></v-divider>
        <br>
        <h2 class="text-center">{{ $t('products') }}</h2>
        <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                {{ $t('name')}}
              </th>
              <th class="text-center">
                {{ $t('quantity') }}
              </th>
              <!-- <th class="text-center">
                {{ $t('totalPrice') }}
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,i) in items"
              :key="i"
              class="text-center"
            >
              <td>{{ item.name ? item.name:'null' }}</td>
              <td>{{ item.count }}</td>

            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <br>
      <br>
        <v-divider></v-divider>
        <br>
        <h2 class="text-center">{{ $t('product_qebz')}}</h2>
        <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                Tip
              </th>
              <th class="text-center">
                Say
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,i) in errors"
              :key="i"
              class="text-center"
            >
            <td v-if="item.type==0">Fiscal İD-nə artıq əlavə edilmişdir</td>
            <td v-else-if="item.type==1">Fiscal İD artıq başqa istifadəçi tərəfindən əlavə edilmişdir</td>
            <td v-else-if="item.type==2">Fiscal İD tapılmadı</td>
            <td v-else-if="item.type==3">Toplam qəbul olunmuş qəbzlərin sayı</td>
            <td v-else>Toplam imtina olunmuş qəbzlər</td>
            <td>{{ item.count }}</td>

            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-container>
    </v-main>
</template>
<script>
import {
    mdiPencil,
    mdiEye,
    mdiRotateRight,
    mdiContentSave 
  } from '@mdi/js'
import axios from 'axios'
import moment from "moment"
import store from '../../store/user'
import i18n from '@/plugins/i18n'
import BarChart from '../parts/Bar.vue'
import PieChart from '../parts/Pie.vue'
  export default {
    components: {
    BarChart,
    PieChart
  },
    data: () => ({
      InstaCount: 0,
      userCount: 0,
      operatorCount: [],
      operatorName: [],
      errors: [],
      InstaSubCount: 0,
      AllRegisterAttempts: 0,
      FailedRegisteration: 0,
      SuccessfulRegisteration: 0,
      buyAvaragePrice: 0,
      cityName: [],
      cityCount: [],
      icons: {
          mdiPencil,
          mdiEye,
          mdiRotateRight,
          mdiContentSave 
      },
      rules: {
          name: [
              val => !!val || "Boş qoymayın!",
              val => (val && val.length <= 25) || "Adı 25 simvoldan kiçik olmalıdır!",
          ],
          req: [
              val => !!val || "Boş qoymayın!",
          ]
      },
      loading: true,
      searchloading: true,
      exportloading: false,
      hover:false,
      config: {
          headers: {
              Authorization: ""
          }
      },
      statuss: [
          { name: "", id: "" },
          { name: i18n.t('approved'), id: 0 },
          { name: i18n.t('pending'), id: 1 },
          { name: i18n.t('rejected'), id: 2 }
      ],
      menu: false,
      menu2: false,
      reguest: {
          startdate: moment().add(-1, "days").format("Y-MM-DD"),
          enddate: moment().add(1, "days").format("Y-MM-DD")
      },
      img: '',
      formupdate: false,
      items: [],
    }),
    methods: {

        onPageChange() {
            this.refresh();
        },
        exportt() {
            this.exportloading = true;
            var reg = this.reguest;
            reg.lang = this.$i18n.locale
            axios.get(this.$store.getters.getUrl + "Excel/ExportStatistics", { ...this.config, responseType: "blob", params: reg })
                .then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "statistika");
                document.body.appendChild(link);
                link.click();
                this.exportloading = false;
            })
                .catch(() => {
                this.$toast.error("Xəta var!");
            });
        },

        refresh() {

            this.cityName.splice(0, this.cityName.length)
            this.cityCount.splice(0, this.cityCount.length)
            this.operatorName.splice(0, this.operatorName.length)
            this.operatorCount.splice(0, this.operatorCount.length)

            this.searchloading = true;

            axios.get(this.$store.getters.getUrl + "Statistics/NewUsers", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                this.userCount = parseInt(response.data.value.length)
  
            });
            axios.get(this.$store.getters.getUrl + "Statistics/SuccessRegistrationRate", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                this.SuccessfulRegisteration = parseInt(response.data.value.successCount)
                this.FailedRegisteration = parseInt(response.data.value.unsuccessCount)
  
            });
            axios.get(this.$store.getters.getUrl + "Statistics/BoughtProducts", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                this.items = response.data.value
  
            });
            axios.get(this.$store.getters.getUrl + "Statistics/ReceiptStatistic", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                this.errors = response.data.value
  
            });
            axios.get(this.$store.getters.getUrl + "Statistics/ReceiptByCity", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                response.data.value.forEach(element => {
                    this.cityName.push(element.city ? element.city: 'null')
                    this.cityCount.push(element.count)
                  })

                  
  
            });
            axios.get(this.$store.getters.getUrl + "Statistics/UsersByOperator", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                response.data.value.forEach(element => {
                  this.operatorName.push(element.operator)
                  this.operatorCount.push(element.count)
                  })
  
            });
            axios.get(this.$store.getters.getUrl + "Statistics/AverageUserPurchase", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                this.buyAvaragePrice = response.data.value.averagePurchaseAmount
  
            });
            
            this.searchloading = false;
            
        },
        format_date(k) {
            return k ? moment(k).format("DD.MM.Y HH:mm:ss") : "";
        },
    },
    created() {
        this.config.headers.Authorization = "Bearer " + store.getters.getToken;
        this.refresh();
        this.searchloading = false;
        
    },
}
</script>
<style>
.statistikimg{
  height: 160px;
  width: 100px;
}
.statistikimg2{
  position:fixed;
  top:100px;
  right:400px;
  width:600px;
  height :900px;
  z-index: 999999;
}
</style>