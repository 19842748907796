<template>
    <v-main>
      <v-container >
        <v-row>
          <v-spacer/>
          <v-col
          cols="12"
          sm="12"
          lg="12"
          md="12"
          class="d-md-inline-flex"
          >
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="reguest.startdate"
                :label="$t('start_date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reguest.startdate"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="reguest.enddate"
                :label="$t('end_date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reguest.enddate"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
            class="mx-3"
            :label="$t('number')"
            v-model="reguest.msisdn"
          ></v-text-field>
          <v-btn
          class="ma-2 text-none"
          :disabled="searchloading"
          :loading="searchloading"
          outlined
          color="indigo"
          @click="refresh"
          >
            {{ $t('search') }}
          </v-btn>
          <v-btn
          class="ma-2 text-none"
          :disabled="exportloading"
          :loading="exportloading"
          outlined
          color="success"
          @click="exportt"
          >
          Export
          </v-btn>
          <!-- <v-btn
          class="ma-2 text-none"
          :disabled="exportloading"
          :loading="exportloading"
          outlined
          color="success"
          @click="exporttx"
          >
          ExportX
          </v-btn> -->
          </v-col>
        </v-row>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                {{ $t('date') }}
              </th>
              <th class="text-center">
                {{ $t('name')}}
              </th>
              <th class="text-center">
                {{ $t('surname') }}
              </th>
              <th class="text-center">
                {{ $t('number') }}
              </th>
              <th class="text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,i) in items"
              :key="i"
              class="text-center"
            >
              <td>{{ format_date(item.createdAt) }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.surname }}</td>
              <td>{{ item.msisdn }}</td>
              <td v-if="item.isProccessed">İcra edilib</td>
              <td v-else>İcra edilməyib</td>

            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-container>
    </v-main>
</template>
<script>
import {
    mdiPencil,
    mdiEye,
    mdiRotateRight,
    mdiContentSave 
  } from '@mdi/js'
import axios from 'axios'
import moment from "moment"
import store from '../../store/user'
import StatisticUpdate from '../parts/StatisticUpdate.vue'
import i18n from '@/plugins/i18n'
  export default {
    data: () => ({
        icons: {
            mdiPencil,
            mdiEye,
            mdiRotateRight,
            mdiContentSave 
        },
        rules: {
            name: [
                val => !!val || "Boş qoymayın!",
                val => (val && val.length <= 25) || "Adı 25 simvoldan kiçik olmalıdır!",
            ],
            req: [
                val => !!val || "Boş qoymayın!",
            ]
        },
        loading: true,
        searchloading: false,
        exportloading: false,
        hover:false,
        config: {
            headers: {
                Authorization: ""
            }
        },
        statuss: [
            { name: "", id: "" },
            { name: i18n.t('approved'), id: 0 },
            { name: i18n.t('pending'), id: 1 },
            { name: i18n.t('rejected'), id: 2 }
        ],
        menu: false,
        menu2: false,
        reguest: {
            msisdn: "",
            startdate: moment().add(-1, "days").format("Y-MM-DD"),
            enddate: moment().add(1, "days").format("Y-MM-DD")
        },
        img: '',
        formupdate: false,
        items: [],
    }),
    methods: {
        rotate(i){
            var chitems = {...this.items}
            chitems[i].rotate+=90;
            if(chitems[i].rotate>=360)
            chitems[i].rotate = 0;
            this.items = {...chitems};
            chitems = [];

        },
        rotateSave(item){
          axios.post(this.$store.getters.getUrl + "Receipt/UpdateImage?rotationAngle="+item.rotate, item, this.config).then(response => {
                    if (response.data.success) {
                        this.$toast.success("Çevrildi!");
                    }
                    else {
                        this.$toast.error("Bir xəta baş verdi. Bir daha sınayın!");
                    }
                }).catch(() => {
                    this.$toast.error("Bir xəta baş verdi!");
                });
        },
        onPageChange() {
            this.refresh();
        },
        exportt() {
            this.exportloading = true;
            this.reguest.FullMsisdn = true;
            axios.get(this.$store.getters.getUrl + "excel/exportportmanatwinners", { ...this.config, responseType: "blob", params: this.reguest })
                .then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "statistika");
                document.body.appendChild(link);
                link.click();
                this.exportloading = false;
            })
                .catch(() => {
                this.$toast.error("Xəta var!");
            });
        },
        exporttx() {
            this.exportloading = true;
            this.reguest.FullMsisdn = false;
            axios.get(this.$store.getters.getUrl + "excel/exportreceipts", { ...this.config, responseType: "blob", params: this.reguest })
                .then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "statistika");
                document.body.appendChild(link);
                link.click();
                this.exportloading = false;
            })
                .catch(() => {
                this.$toast.error("Xəta var!");
            });
        },
        update(item) {
            this.uitem = { ...item };
            this.formupdate = true;
        },
        geUpform(value){
          this.formupdate = value;
        },
        refresh() {
            axios.get(this.$store.getters.getUrl + "Winner/PortmanatWinners", {
                headers: this.config.headers,
                params: this.reguest
            }).then(response => {
                this.items = response.data.value;
                this.searchloading = false;
                this.items.map((i) => {
                  i.rotate = 0;
                })
            }).catch(error => {
                this.$toast.error("Xəta var!");
            });
        },
        format_date(k) {
            return k ? moment(k).format("DD.MM.Y HH:mm:ss") : "";
        },
    },
    created() {
        this.config.headers.Authorization = "Bearer " + store.getters.getToken;
        this.refresh();
        
    },
    components: { StatisticUpdate }
}
</script>
<style>
.statistikimg{
  height: 160px;
  width: 100px;
}
.statistikimg2{
  position:fixed;
  top:100px;
  right:400px;
  width:600px;
  height :900px;
  z-index: 999999;
}
</style>